import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
    galleryTitleContainer,
    galleryContainer,
    galleryTitle,
    gallerySubtitle,
    tagsContainer,
    tagButton,
    tagButtonActive,
    galleryImageOverlay,
    galleryImageContainer,
    galleryImageWrapper,
    overlayText,
    overlayTags,
    overlayTagsContainer,
    tallTwo,
    tallThree,
    tallFour,
    tallFive,
    loadMoreButton,
} from "./Gallery.module.css"
import {
    searchBarContainer,
    searchBar,
    searchBarLabel,
    categorySelect,
    searchBarInput,
} from "./SearchBar.module.css"

const Gallery = () => {
    const data = useStaticQuery(graphql`
        query {
            allMdx {
                nodes {
                    frontmatter {
                        title
                        description
                        tags
                        hero_image {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        category
                    }
                    id
                    slug
                }
            }
        }
    `)

    const [state, setState] = useState({
        query: "",
        blocks: [],
        showLoadMoreButton: true,
        tag: "",
        tagIsActive: false,
    })

    const { blocks, showLoadMoreButton, query, tag, tagIsActive } = state

    const allBlocks = data.allMdx.nodes
    const hasSearchResults = query !== ""

    const handleChange = (event) => {
        const query = event.target.value

        const filteredData = allBlocks.filter((block) => {
            const { title, category, description, tags } = block.frontmatter
            return (
                tags.toLowerCase().includes(query.toLowerCase()) ||
                title.toLowerCase().includes(query.toLowerCase()) ||
                category.toLowerCase().includes(query.toLowerCase()) ||
                description.toLowerCase().includes(query.toLowerCase())
            )
        })
        setState({
            query,
            showLoadMoreButton: true,
            blocks: filteredData,
        })
    }

    const handleTagClick = (tag) => {
        // If the tag is already active, reset the state
        if (tag === tagIsActive) {
            setState({
                tag: "",
                tagIsActive: false,
                showLoadMoreButton: false,
                blocks: allBlocks,
            })
            return
        }

        const filteredData = allBlocks.filter((block) => {
            const { tags } = block.frontmatter
            return tags.toLowerCase().includes(tag.toLowerCase())
        })
        setState({
            tag,
            tagIsActive: tag,
            showLoadMoreButton: true,
            blocks: filteredData,
        })
    }

    const blocksCount = blocks.length

    // Count number of blocks and set styling specifically for 1-3 blocks
    const filteredStylingMaxWidth =
        blocksCount <= 3 && blocksCount > 0
            ? { maxWidth: "300px" }
            : { maxWidth: "100%" }

    const filteredStylingUseFlex =
        blocksCount <= 3 && blocksCount > 0
            ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
              }
            : {}

    const handleLoadMore = () => {
        setState({ showLoadMoreButton: false, blocks: allBlocks })
        // TODO: handle load more with filtered block content
        // Currently only loads all blocks
    }

    useEffect(() => {
        // Limit the number of blocks to display initially
        const limitItemCount = 15
        const maxBlocksInitial =
            allBlocks.length > limitItemCount
                ? allBlocks.slice(0, limitItemCount)
                : allBlocks

        setState({
            showLoadMoreButton: true,
            blocks: maxBlocksInitial,
        })
    }, [hasSearchResults])

    // TODO: Add dynamic tags names based on the blocks
    const buttons = ["images", "2 column", "3 column"]

    return (
        <div>
            <div className={galleryTitleContainer}>
                <div>
                    <h2 className={gallerySubtitle}>
                        Check out the freshest blocks on the block
                    </h2>
                    <h1 className={galleryTitle}>Dyspatch Block Gallery</h1>
                </div>
            </div>

            <div className={searchBarContainer}>
                <div className={searchBar}>
                    <label className={searchBarLabel}>Category</label>
                    <br />
                    <select className={categorySelect} onChange={handleChange}>
                        <option value="" selected>
                            All
                        </option>
                        <option value="Header">Headers</option>
                        <option value="Footer">Footers</option>
                        <option value="Button">Buttons</option>
                        <option value="Text">Text</option>
                        <option value="Layout">Layout</option>
                        <option value="Hero Image">Hero Images</option>
                        <option value="Social">Social Elements</option>
                    </select>
                </div>
                <div>
                    <label className={searchBarLabel}>Search</label>
                    <br />
                    <input
                        className={searchBarInput}
                        type="text"
                        placeholder="footer, header, etc"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div
                style={{
                    marginLeft: "150px",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    marginTop: "10px",
                }}
            >
                <p>popular tags</p>
            </div>
            <div className={tagsContainer}>
                {buttons.map((name, index) => (
                    <button
                        className={
                            tagIsActive === name
                                ? tagButton + " " + tagButtonActive
                                : tagButton
                        }
                        key={name + index}
                        onClick={() => handleTagClick(name)}
                    >
                        {name}
                        {tagIsActive === name ? <span>×</span> : ""}
                    </button>
                ))}
            </div>

            <div className={galleryContainer} style={filteredStylingUseFlex}>
                {blocks.map((node) => {
                    const image = getImage(node.frontmatter.hero_image)

                    let imageHeightClass = tallTwo

                    // Get the height of the image and assign a class based on the height
                    // This dynamically sets the height of the grid span for each image
                    if (image.height >= 800) {
                        imageHeightClass = tallFive
                    } else if (image.height >= 400) {
                        imageHeightClass = tallFour
                    } else if (image.height >= 200) {
                        imageHeightClass = tallThree
                    } else {
                        imageHeightClass = tallTwo
                    }

                    return (
                        <div
                            className={
                                galleryImageContainer + " " + imageHeightClass
                            }
                            key={node.id}
                            style={filteredStylingMaxWidth}
                        >
                            <Link
                                to={node.slug}
                                style={{ textDecoration: "none" }}
                            >
                                <div className={galleryImageWrapper}>
                                    <GatsbyImage
                                        objectFit="contain"
                                        image={image}
                                        alt=""
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: "5px",
                                        }}
                                    />
                                </div>
                                <div className={galleryImageOverlay}>
                                    <div className={overlayText}>
                                        {node.frontmatter.title} <br />
                                    </div>
                                    <div className={overlayTagsContainer}>
                                        {node.frontmatter.tags
                                            .split(",")
                                            .map((tag) => (
                                                <button className={overlayTags}>
                                                    {tag.trim()}
                                                </button>
                                            ))}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })}
            </div>
            {showLoadMoreButton ? (
                <div style={{ backgroundColor: "#ffffff", padding: "15px" }}>
                    <div
                        style={{
                            margin: "auto 0",
                            textAlign: "center",
                        }}
                    >
                        <button
                            className={loadMoreButton}
                            onClick={handleLoadMore}
                        >
                            Load more
                        </button>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    )
}

export default Gallery
